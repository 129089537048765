import React, { createContext, useContext, useState } from 'react';

const CheckoutContext = createContext();

export function useCheckout() {
  return useContext(CheckoutContext);
}

export function CheckoutProvider({ children }) {
  const [formData, setFormData] = useState({});
  const [numParcelas, setNumParcelas] = useState(12); // Defina um valor padrão para numParcelas
  const [quantidadeProduto, setQuantidadeProduto] = useState(1); // Defina um valor padrão para quantidadeProduto
  const [valor, setValor] = useState(1918.80); // Defina um valor padrão para valor
  const [cupomId, setCupomId] = useState(null);
  const [valorFinal, setvalorFinal] = useState(191880);

  return (
    <CheckoutContext.Provider value={{ formData, setFormData, numParcelas, setNumParcelas, quantidadeProduto, setQuantidadeProduto, valor, setValor, valorFinal, setvalorFinal, cupomId, setCupomId }}>
      {children}
    </CheckoutContext.Provider>
  );
}
