import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import styles from '../styles/Cartao.module.css';
import { useCheckout } from '../states/CheckoutContext'; // Importe o gancho useCheckout
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Importe useHistory para lidar com o redirecionamento
import axios from 'axios'; // Importe o axios
import { baseUrl } from '../config';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

// Importação das imagens
import logo from './../images/logo.png';
import selo_seguranca from './../images/selo_seguranca.svg';
import info from './../images/info.svg';
import sucesso from './../images/sucesso.svg';
import cartao from './../images/cartao.svg';
import pix from './../images/pix.svg';
import payment from './../images/payment.svg';

function Cartao() {

    const [showPopup, setShowPopup] = useState(false);
    const [showError, setShowError] = useState(false);

    // Use o gancho useCheckout para acessar os dados do contexto
    const { formData } = useCheckout();
    const navigate = useNavigate(); // Obtenha o objeto history

    let { id } = useParams();

    //Formatação do valor em R$
    function formatCurrency(value) {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(value);
    }
    // Função para remover vírgulas e pontos de uma string e retornar um valor numérico
    const removeCommasAndDots = (str) => {
        if (typeof str !== 'string') {
            return str;
        }

        const stringWithoutCommasAndDots = str.replace(/[,.]/g, '');
        return parseFloat(stringWithoutCommasAndDots);
    };

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('credito');
    const [formData2, setFormData] = useState({
        numero_cartao: '',
        nome_cartao: '',
        mes_expiracao: '',
        ano_expiracao: '',
        codigo_cartao: '',
    });
    //Retornando os dados do context que foram guardados anteriormente
    const { numParcelas, quantidadeProduto, valor, valorFinal, cupomId } = useCheckout(); // Acesse os valores do contexto

    const [errors, setErrors] = useState({});

    const validateField = (name, value) => {
        let error = "";

        switch (name) {
            case 'numero_cartao':
                if (!value || value.includes('_')) error = "Número do cartão inválido.";
                break;
            case 'nome_cartao':
                if (!value) error = "Nome é obrigatório.";
                break;
            case 'mes_expiracao':
                const month = parseInt(value);
                if (!value || value.includes('_') || month < 1 || month > 12) error = "Mês de expiração inválido.";
                break;
            case 'ano_expiracao':
                if (!value || value.includes('_')) error = "Data de expiração inválida.";
                break;
            case 'codigo_cartao':
                if (!value) error = "Código de segurança inválido.";
                break;

            default:
                break;
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        validateField(name, value); // Chama a função de validação ao mudar o valor do campo
    };

    // Função para enviar os dados da compra para o gateway
    const handleConcludePurchase = async () => {

            // Combine os dados do formulário
            const combinedFormData = {
                nome: formData.nome,
                email: formData.email,
                telefone: formData.whatsapp,
                data_nascimento: '',
                identificador: formData.cpf,
                cep: formData.cep,
                endereco: formData.endereco,
                bairro: formData.bairro,
                numero: formData.numero,
                cidade: formData.cidade,
                estado: formData.estado,
                complemento: formData.complemento,
                numero_cartao: formData2.numero_cartao || '',
                nome_cartao: formData2.nome_cartao || '',
                mes_expiracao: formData2.mes_expiracao || '',
                ano_expiracao: formData2.ano_expiracao || '',
                codigo_cartao: formData2.codigo_cartao || '',
                parcelas: numParcelas || '',
                metodo_pagamento: selectedPaymentMethod,
                quantidade: quantidadeProduto,
                valor: valorFinal,
                cupomId: cupomId || '',
            };

            try {
                setShowPopup(true);

                // Após 20 segundos, troque a mensagem
                setTimeout(() => {
                    setShowError(true);
                }, 20000);


                // Primeiro, enviamos os dados de pagamento ao gateway
                const endpoint = `/gateway`;
                const url = (baseUrl + endpoint).replace(/\/+$/, '');
                const gatewayResponse = await axios.post(url, combinedFormData);

                if (gatewayResponse.data.success) {
                    // Se tiver sucesso com o gateway, recebe o ID do pedido retornado pela resposta
                    const pedidoId = gatewayResponse.data.id_transaction;

                    //Caso for pagamento por cartão redireciona para página de Cartão
                    if (selectedPaymentMethod === 'credito') {
                        // Redirecionar para /cartao com o ID da transação na rota
                        navigate(`/cartao/${pedidoId}`);
                    } else {
                        // Redirecionar para /pix com o ID da transação na rota
                        navigate(`/pix/${pedidoId}`);
                    }


                    console.log("Compra concluída com sucesso!");
                } else {
                    console.error("Erro ao processar a compra no gateway");
                }
            } catch (error) {
                console.error("Erro ao concluir a compra", error);
            }

    };

    const [transactionStatus, setTransactionStatus] = useState(null); // Estado para armazenar o status da transação

    useEffect(() => {
        // Função assíncrona para buscar o status da transação no Pagarme
        const fetchTransactionStatus = async () => {
            try {
                const endpoint = 'status'; // Caminho após o domínio
                const url = `${baseUrl}/${endpoint}/${id}`; // Monta a URL completa com baseUrl

                // Fazer uma solicitação para o servidor ou API Pagarme para obter o status da transação com base no "transactionId"
                const response = await axios.get(url);

                const transactionData = response.data;

                // Defina o status da transação com base nos dados da resposta
                setTransactionStatus(transactionData.status); // Suponha que a resposta tenha um campo "status"

            } catch (error) {
                console.error('Erro ao buscar o status da transação:', error);
            }
        };

        if (id) {
            // Se houver um "transactionId" na URL, busque o status da transação
            fetchTransactionStatus();
        }
    }, [id]);

    //Contante que valida os campos se não estão vazios
    const validarCampos = () => {

        //Verificando se é pix
        if (selectedPaymentMethod === 'credito') {
            const camposObrigatorios = [
                'numero_cartao',
                'nome_cartao',
                'mes_expiracao',
                'ano_expiracao',
                'codigo_cartao',
            ];
            const errors = {};
            camposObrigatorios.forEach((campo) => {
                if (!formData2[campo]) {
                    errors[campo] = `${campo.replace('_', ' ')} é obrigatório.`;
                }
            });
            setErrors(errors);

            const retorno = Object.keys(errors).length === 0; // Retorna true se não houver erros

            if(retorno === true){
                //Se não tiver erro, chama a compra
                handleConcludePurchase();
            }

        }else{
            //Para método pix
            handleConcludePurchase();
        }

    };

    // Gere automaticamente as opções de parcelamento
    const parcelasOptions = [];
    for (let i = 1; i <= 12; i++) {
        const parcelaValue = (valor * 100) / i; // Calcula o valor da parcela
        const isOptionSelected = numParcelas === i; // Verifica se esta é a parcela selecionada
        const formattedParcelaValue = formatCurrency(parcelaValue / 100);

        parcelasOptions.push(
            <option key={i} value={i} defaultValue={isOptionSelected ? "selected" : ""}>
                {i}x de R$ {formattedParcelaValue} (Sem juros)
            </option>
        );
    }

    useEffect(() => {
        console.log(`Parcela selecionada: ${numParcelas}`);
        console.log(`Quantidade de produtos: ${quantidadeProduto}`);
        console.log(`Valor total: ${valor}`);
        console.log(`Valor Unitário: ${valorFinal}`);
        console.log(`ID do cupom: ${cupomId}`);
    }, [numParcelas, quantidadeProduto, valor, valorFinal, cupomId]);


    return (
        <div>
            {/* Popup */}
            {showPopup && (
                <div className="pop_up_loading" id="load_pag">
                    <div className="loading-spinner" id="loading"></div>
                    {showError ? (
                        <p>Parece que ocorreu um erro ao processar seu pedido. Entre em contato com suporte.</p>
                    ) : (
                        <p>Processando pagamento...</p>
                    )}
                </div>
            )}
            {/* Cabeçalho normal do checkout */}
            <div className="contador">
                <div className="container-contador">
                    <div className="logo">
                        <img src={logo} alt="Logo" />
                    </div>
                    <div className="pagamento">
                        <img src={selo_seguranca} alt="Selo de Segurança" />
                    </div>
                </div>
            </div>
            {/* Load de carregamento */}
            <div className={styles.loading_spinner} id="loading"></div>

            {/* Renderize a seção com base no status da transação */}
            {transactionStatus === 'paid' ? (

                < div className={styles.bloco_aprovada} id="compra_aprovada">
                    <div className={styles.compra_aprovada}>
                        <div className={styles.titulo}>
                            <img src={sucesso} alt="Sucesso" />
                            <h1>A sua compra foi confirmada!</h1>
                            <p>Parabéns! Você acaba de adquirir uma das edições mais belas da Biblia Sagrada!</p>
                        </div>
                        <div className={styles.informacao2}>
                            <img src={info} alt="Informações" />
                            <p>Acompanhe pelo seu e-mail todas as etapas da sua compra</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.processar_cartao} id="caixa_cartao">
                    <form id="checkout-form">
                        <div className="colunas">
                            <div className="blocos">
                                <div className={styles.bloco_esq} id="terceira_etapa">
                                    <div className="cabecalho">
                                        <div className={styles.texto_cartao}>
                                            <img src={payment} alt="Erro" />
                                            <h2>Ops.... parece que houve uma falha no pagamento</h2>
                                            <p>Tente novamente utilizando outro cartão ou escolha outra forma de pagamento.</p>
                                        </div>
                                        <div className={styles.paragrafo}>
                                            <p>Selecione a opção de pagamento</p>
                                        </div>
                                    </div>

                                    <div className="corpo-form" id="corpo-form03">
                                        <div className="option-pag">
                                            <div className="acordion" id="cartao">
                                                <div className="itens_pagamento" id="btn_pagamentos">
                                                    {/* Manipulando o evento de clique para atualizar o estado de pagamento. */}
                                                    <div
                                                        className={`btn_opcao ${selectedPaymentMethod === 'credito' ? 'btn_opcao_ativo' : ''}`}
                                                        id="btn_cartao"
                                                        onClick={() => setSelectedPaymentMethod('credito')}
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="opcao_pagamento"
                                                            value="credito"
                                                            id="radio_pag_1"
                                                            checked={selectedPaymentMethod === 'credito'}
                                                        />
                                                        <img src={cartao} alt="Cartão" />
                                                        <p>Cartão de Crédito</p>
                                                    </div>
                                                    <div
                                                        className={`btn_opcao ${selectedPaymentMethod === 'pix' ? 'btn_opcao_ativo' : ''}`}
                                                        id="btn_pix"
                                                        onClick={() => setSelectedPaymentMethod('pix')}
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="opcao_pagamento"
                                                            value="pix"
                                                            id="radio_pag_4"
                                                            checked={selectedPaymentMethod === 'pix'}
                                                        />
                                                        <img src={pix} alt="Pix" />
                                                        <p>Pix</p>
                                                    </div>
                                                </div>

                                                <input type="hidden" name="forma_pag" value="1" id="forma_pagamento" />

                                                {selectedPaymentMethod === 'credito' && (
                                                    <div className="conteudo-cartao segundatela" id="conteudo_cartao">
                                                        <div className="linha_resumo"></div>

                                                        {/* Seção do cartão de crédito */}
                                                        <div className="estrutura-pc-inputs">
                                                            <div className="group-input-pc">
                                                                <InputMask
                                                                    mask="9999 9999 9999 9999"
                                                                    maskChar=""
                                                                    value={formData2.numero_cartao}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    {() => (
                                                                        <TextField
                                                                            label="Número do cartão"
                                                                            variant="outlined"
                                                                            type="text"
                                                                            fullWidth
                                                                            error={!!errors.numero_cartao}
                                                                            helperText={errors.numero_cartao || ''}
                                                                            name="numero_cartao"
                                                                            id="input13"
                                                                            placeholder="Número do cartão"
                                                                            required
                                                                        />
                                                                    )}
                                                                </InputMask>
                                                            </div>

                                                            <div className="group-input-pc">
                                                                <TextField
                                                                    label="Nome impresso no cartão"
                                                                    variant="outlined"
                                                                    maxLength="50"
                                                                    name="nome_cartao"
                                                                    type="text"
                                                                    onChange={handleInputChange}
                                                                    value={formData2.nome_cartao}
                                                                    id="input14"
                                                                    placeholder="Número do cartão"
                                                                    error={!!errors.nome_cartao}
                                                                    helperText={errors.nome_cartao || ''}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="estrutura-pc-inputs">
                                                            <div className="coluna2">
                                                                <div className="group-input-pc">
                                                                    <InputMask
                                                                        mask="99"
                                                                        maskChar=""
                                                                        value={formData2.mes_expiracao}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        {() => (
                                                                            <TextField
                                                                                label="Mês"
                                                                                variant="outlined"
                                                                                type="text"
                                                                                fullWidth
                                                                                error={!!errors.mes_expiracao}
                                                                                helperText={errors.mes_expiracao || ''}
                                                                                name="mes_expiracao"
                                                                                id="input15"
                                                                                placeholder="00"
                                                                                required
                                                                            />
                                                                        )}
                                                                    </InputMask>
                                                                </div>
                                                                <div className="group-input-pc">
                                                                    <InputMask
                                                                        mask="99"
                                                                        maskChar=""
                                                                        value={formData2.ano_expiracao}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        {() => (
                                                                            <TextField
                                                                                label="Ano"
                                                                                variant="outlined"
                                                                                type="text"
                                                                                fullWidth
                                                                                error={!!errors.ano_expiracao}
                                                                                helperText={errors.ano_expiracao || ''}
                                                                                name="ano_expiracao"
                                                                                id="input16"
                                                                                placeholder="00"
                                                                                required
                                                                            />
                                                                        )}
                                                                    </InputMask>
                                                                </div>
                                                                <div className="group-input-pc">
                                                                    <InputMask
                                                                        mask="9999"
                                                                        maskChar=""
                                                                        value={formData2.codigo_cartao}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        {() => (
                                                                            <TextField
                                                                                label="Código"
                                                                                variant="outlined"
                                                                                type="text"
                                                                                fullWidth
                                                                                error={!!errors.codigo_cartao}
                                                                                helperText={errors.codigo_cartao || ''}
                                                                                name="codigo_cartao"
                                                                                id="input17"
                                                                                placeholder="CVV"
                                                                                required
                                                                            />
                                                                        )}
                                                                    </InputMask>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <input type="hidden" name="id_produto" value="1918" />

                                                        <div className={styles.estrutura_pc_inputs}>
                                                            <div className="group-input-pc">
                                                                <label htmlFor="input18">Parcelas</label>
                                                                <select name="parcelas" id="input18">
                                                                    {parcelasOptions}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div id="retorna_erro"></div>
                                                    </div>
                                                )}

                                                {selectedPaymentMethod === 'boleto' && (
                                                    <div className="conteudo-boleto" id="conteudo_boleto">
                                                        <p>Clique em "Concluir Compra" e você poderá ver o BOLETO na próxima etapa</p>
                                                    </div>
                                                )}
                                                {selectedPaymentMethod === 'pix' && (
                                                    <div className="conteudo-boleto" id="conteudo_pix">
                                                        <p>Clique em "Concluir Compra" e você poderá ver o PIX na próxima etapa</p>
                                                    </div>
                                                )}

                                                <div className="botao-form" id="botao_final" onClick={() => {
                                                    validarCampos(); // Chama a função para processar a compra
                                                    setShowPopup(true); // Define showPopup como true para mostrar o popup
                                                }}>
                                                    Concluir Compra
                                                </div>

                                                <p id="final_Error" className="alert_error">
                                                    Favor preencher todos os campos corretamente.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            )
            }
        </div >
    );
}

export default Cartao;
