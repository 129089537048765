import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Checkout from './pages/Checkout';
import Pix from './pages/Pix';
import Cartao from './pages/Cartao';
import Lancamento from './pages/PaginaDeLancamento';
//import PlanoB from './pages/PlanoB';
import { CheckoutProvider } from './states/CheckoutContext';

function App() {
  return (
    <Router>
      <CheckoutProvider>
        <Routes>
        <Route path="/" element={<Lancamento />} />
          <Route path="/checkout" element={<Lancamento />} />
          <Route path="/desenvolvimento" element={<Checkout/>} />
          <Route path="/pix/:id?" element={<Pix />} />
          <Route path="/cartao/:id?" element={<Cartao />} />
        </Routes>
      </CheckoutProvider>
    </Router>
  );
}

export default App;
