import React, { useState, useEffect } from 'react';
import styles from '../styles/Pix.module.css';
import styles_cartao from '../styles/Cartao.module.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../config';

// Importação das imagens
import logo from './../images/logo.png';
import selo_seguranca from './../images/selo_seguranca.svg';
import aguardar_pix from './../images/aguardando_pix.svg';
import qr_code from './../images/qr_code.png';
import info from './../images/info.svg';
import sucesso from './../images/sucesso.svg';
import payment from './../images/payment.svg';

function Pix() {
  let { id } = useParams();
  const [copySuccess, setCopySuccess] = useState('Pix Copia e Cola');
  const [transactionDetails, setTransactionDetails] = useState({ pixCode: '', qrCodeURL: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [checkedPayment, setCheckedPayment] = useState(false);

  useEffect(() => {
    getStatus();
  }, [id]);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(document.getElementById('codigo').innerText)
      .then(() => {
        setCopySuccess('Copiado!');
        setTimeout(() => setCopySuccess('Pix Copia e Cola'), 2000);
      })
      .catch(err => console.error('Erro ao copiar: ', err));
  };

  const handleConfirmPayment = () => {
    setCheckedPayment(true);
    getStatus();
  };

  const getStatus = async () => {
    try {
      
      const endpoint = 'status_pix'; // Caminho após o domínio
      const url = `${baseUrl}/${endpoint}/${id}`; // Monta a URL completa com baseUrl
      
      const response = await axios.get(url);
      const data = response.data;

      setTransactionStatus(data.status);

      setTransactionDetails({
        pixCode: data.qrCode,
        qrCodeURL: data.qrCodeURL
      });
    } catch (error) {
      console.error('Erro ao confirmar o status do pagamento:', error);
      setErrorMessage('Erro ao buscar os detalhes do pagamento. Tente novamente mais tarde.');
    }
  };
  //Mudança de cor da barra de pagamento
  const getTituloPixStyle = () => {
    if (checkedPayment && transactionStatus !== 'paid') {
      return { borderTop: '5px solid #ff4d4d' };
    }
    return {};
  };

  return (
    <main>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      <div className='contador'>
        <div className='container-contador'>
          <div className='logo'>
            <img src={logo} alt="Logo" />
          </div>
          <div className='pagamento'>
            <img src={selo_seguranca} alt="Selo de Segurança" />
          </div>
        </div>
      </div>

      {transactionStatus === 'paid' ? (
        <div className={styles_cartao.bloco_aprovada} id="compra_aprovada">
          <div className={styles_cartao.compra_aprovada}>
            <div className={styles_cartao.titulo}>
              <img src={sucesso} alt="Sucesso" />
              <h1>A sua compra foi confirmada!</h1>
              <p>Parabéns! Você acaba de adquirir uma das edições mais belas da Biblia Sagrada!</p>
            </div>
            <div className={styles_cartao.informacao2}>
              <img src={info} alt="Informações" />
              <p>Acompanhe pelo seu e-mail todas as etapas da sua compra</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.compra_aprovada} id="tela_inicial">
          <div className={styles.titulo_pix} style={getTituloPixStyle()}>

            {(!checkedPayment || transactionStatus === 'paid') && (
              <>
                <img src={aguardar_pix} alt="Aguardando Pix" />
                <h1>Aguardando pagamento.</h1>
                <p>O próximo passo é efetuar o pagamento do PIX.</p>
                <p><span>Importante pagar somente pelo QR code ou PIX copia e cola gerado para identificarmos o seu pagamento.</span></p>
              </>
            )}

            {checkedPayment && transactionStatus !== 'paid' && (
              <>
                <img src={payment} alt="payment" />
                <h1>Não identificamos o seu pagamento.</h1>
                <p><span>Importante pagar somente pelo QR code ou PIX copia e cola gerado para identificarmos o seu pagamento.</span></p>
                <p>Verifique novamente se pagou da forma correta e clique no botão "CONFIRMAR PAGAMENTO"</p>
              </>
            )}

            <div className={styles.codigo_pix}>
              <img src={transactionDetails.qrCodeURL || qr_code} alt="Código Pix" />
              <div className={styles.informacoes_adicionais}>
                <h3>Informações do beneficiário</h3>
                <p><b>Nome:</b> Minha Biblioteca Católica</p>
                <p><b>CNPJ:</b> 28.924.792/0001-00</p>
                <p><b>Banco:</b> Banco do Brasil</p>
              </div>
            </div>

            <div className={styles.area_copiar}>
              <div className={styles.caixa_codigo}>
                <p id="codigo">{transactionDetails.pixCode || '123456789abcdefghij123456789'}</p>
                <div className={styles.btn_copiar} onClick={handleCopyClick}>{copySuccess}</div>
              </div>
            </div>

            <div className={styles.btn_finalizar} onClick={handleConfirmPayment}>CONFIRMAR PAGAMENTO</div>
          </div>

          <div className={styles.informacao}>
            <img src={info} alt="info" />
            <p>Após efetuar o pagamento, acompanhe pelo seu e-mail todas as etapas da sua compra.</p>
          </div>
        </div>
      )}
    </main>
  );
}

export default Pix;
