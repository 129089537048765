import React, { useEffect } from 'react';

function PaginaDeLancamento() {
  // Use useEffect para redirecionar imediatamente quando a página é carregada
  useEffect(() => {
    window.location.href = 'https://bibliotecacatolica.com.br/produtos/biblia';
  }, []);

  // Você pode adicionar conteúdo adicional ao seu componente aqui, se necessário
  return (
    <div>
    </div>
  );
}

export default PaginaDeLancamento;
